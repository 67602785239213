import request from '@/utils/request';
import { envInfo } from '@/constants/envInfo';

/**
 * 规则分页查询
 * @param params
 */
export function findRulePage(params) {
  return request({
    url: envInfo.bgApp.rulesPath + '/rule/page',
    method: 'get',
    params,
  });
}

/**
 * 规则分页查询
 * @param params
 */
export function findRuleRelation(params) {
  return request({
    url: envInfo.bgApp.rulesPath + '/rule/find/ruleRelation',
    method: 'get',
    params,
  });
}

/**
 * 规则分页查询
 * @param params
 */
export function getRuleDetailByKey(params) {
  //  https://sit.gspark.cc/saascloud/saas/rules/rule/details?ruleKey=QrWX9rbWVkP
  return request({
    url: envInfo.bgApp.rulesPath + '/rule/details',
    method: 'get',
    params,
  });
}

/**
 * 规则列表
 * @returns
 */
export function findRuleList(params) {
  return request({
    url: envInfo.bgApp.rulesPath + '/rule/list',
    method: 'get',
    params,
  });
}

/**
 * 创建规则
 * @param params
 */
export function createRule(params) {
  let urlPath = envInfo.bgApp.rulesPath + '/rule/create';
  // if (params.ruleType == 'schedule') {
  //   urlPath = envInfo.bgApp.schedulePath + '/rule/create';
  // }
  return request({
    url: urlPath,
    method: 'post',
    data: params,
  });
}

/**
 * 更新规则
 * @param params
 */
export function updateRule(params) {
  let urlPath = envInfo.bgApp.rulesPath + '/rule/update';
  // if (params.ruleType == 'schedule') {
  //   urlPath = envInfo.bgApp.schedulePath + '/rule/update';
  // }
  return request({
    url: urlPath,
    method: 'post',
    data: params,
  });
}

/**
 * 删除规则
 * @param params
 */
export function deleteRuleById(params) {
  let urlPath = envInfo.bgApp.rulesPath + '/rule/delete/' + params.ruleId;
  // if (params.ruleType == 'schedule') {
  //   urlPath = envInfo.bgApp.schedulePath + '/rule/delete/' + params.ruleId;
  // }
  return request({
    url: urlPath,
    method: 'delete',
  });
}

/**
 * 批量删除规则
 * @param params
 */
export function batchDeleteRule(params) {
  return request({
    url: envInfo.bgApp.rulesPath + '/rule/delete/batch',
    method: 'put',
    data: params,
  });
}

/**
 * 更新规则状态
 * @param params
 */
export function updateRuleStatus(params) {
  let urlPath = envInfo.bgApp.rulesPath + '/rule/updateStatus';
  // if (params.ruleType == 'schedule') {
  //   urlPath = envInfo.bgApp.schedulePath + '/rule/updateStatus';
  // }
  return request({
    url: urlPath,
    method: 'post',
    data: params,
  });
}

/**
 * 网关边缘规则查询分页
 * @param params
 * @returns
 */
export function findEdgeRulePage(params) {
  return request({
    url: envInfo.bgApp.rulesPath + '/rule/edgePage',
    method: 'get',
    params,
  });
}

/**
 * 网关边缘规则列表
 * @param params
 * @returns
 */
export function findEdgeRuleList(params) {
  return request({
    url: envInfo.bgApp.rulesPath + '/rule/list',
    method: 'get',
    params,
  });
}

/**
 * 绑定边缘规则
 * @param params
 */
export function bindEdgeRule(params) {
  return request({
    url: envInfo.bgApp.rulesPath + '/rule/edge/bind',
    method: 'post',
    data: params,
  });
}

/**
 * 边缘规则解绑
 * @param params
 */
export function unbindEdgeRule(params) {
  return request({
    url: envInfo.bgApp.rulesPath + '/rule/edge/unbind',
    method: 'post',
    data: params,
  });
}

/**
 * 更新网关边缘规则状态
 * @param params
 */
export function updateEdgeRuleStatus(params) {
  return request({
    url: envInfo.bgApp.rulesPath + '/rule/edge/updateStatus',
    method: 'post',
    data: params,
  });
}

//获取所有用户
export function getUserList(params) {
  return request({
    url: envInfo.bgApp.tenantPath + '/tenant/user/userList',
    method: 'get',
    params,
  });
}

/**
 * 获取规则版本历史记录
 * @param params
 */
export function getRuleReleaseHistory(params) {
  return request({
    url: envInfo.bgApp.dorisPath + '/rdata/ruleVersion/history/page',
    method: 'get',
    params,
  });
}

/**
 * 获取规则触发记录
 * @param params
 */
export function getRuleTriggerHistory(params) {
  return request({
    url: envInfo.bgApp.dorisPath + '/rdata/triggerLog/trigger/page',
    method: 'get',
    params,
  });
}

/**
 * 获取规则版本详情
 * @param params
 */
export function getRuleHistoryDetail(params) {
  return request({
    url: envInfo.bgApp.dorisPath + '/rdata/ruleVersion/history/detail',
    method: 'get',
    params,
  });
}

// 查询航空灯规则
export function getAviationLampControlRule(params) {
  return request({
    url: envInfo.bgApp.rulesPath + '/rule/aviationLampControlRule',
    method: 'get',
    params,
  });
}

// 查询外立面灯规则
export function getOutwardLampControlRule(params) {
  return request({
    url: envInfo.bgApp.rulesPath + '/rule/outwardLampControlRule',
    method: 'get',
    params,
  });
}

// 更新航空灯规则
export function updateAviationLampControlRule(params) {
  return request({
    url: envInfo.bgApp.rulesPath + '/rule/updateAviationLampControlRule',
    method: 'post',
    data: params,
  });
}

// 更新外立面灯规则
export function updateOutwardLampControlRule(params) {
  return request({
    url: envInfo.bgApp.rulesPath + '/rule/updateOutwardLampControlRule',
    method: 'post',
    data: params,
  });
}

// 获取设备开关状态
export function getSwtichValue() {
  return request({
    url:
      envInfo.bgApp.thingsPath +
      '/device/codeShadowList?deviceCode=GS2-Philips',
    method: 'get',
  });
}

// status，true开启，false关闭
// GET 控制航空灯规则
export function controlAviationLamp(params) {
  return request({
    url: envInfo.bgApp.rulesPath + '/rule/controlAviationLamp',
    method: 'get',
    params,
  });
}

// GET 控制外立面灯规则
export function controlOutwardLamp(params) {
  return request({
    url: envInfo.bgApp.rulesPath + '/rule/controlOutwardLamp',
    method: 'get',
    params,
  });
}
