var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "app-container product-list-comp" },
    [
      _c("div", { staticClass: "filter-line" }, [
        _c(
          "span",
          [
            _c(
              "el-button",
              { attrs: { type: "primary" }, on: { click: _vm.createProduct } },
              [_vm._v("添加产品")]
            ),
            _c(
              "el-tooltip",
              {
                staticClass: "item",
                attrs: {
                  effect: "dark",
                  disabled: _vm.selectionList.length > 0,
                  content: _vm.$t("commons.multipleSelectionOne"),
                  placement: "right",
                },
              },
              [
                _c(
                  "el-button",
                  {
                    class:
                      _vm.selectionList.length === 0 && "not-allowed-button",
                    attrs: {
                      type: "danger",
                      icon: "el-icon-delete",
                      plain: "",
                    },
                    on: { click: _vm.batchDelete },
                  },
                  [_vm._v(_vm._s(_vm.$t("commons.delete")) + "\n        ")]
                ),
              ],
              1
            ),
            _c(
              "el-button",
              {
                attrs: { plain: "", icon: "el-icon-refresh-right" },
                on: { click: _vm.freshHandler },
              },
              [_vm._v("刷新\n      ")]
            ),
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass: "header-filter-button",
            class: _vm.dataset.filterLine.show
              ? "filter-button-open"
              : "filter-button-close",
            on: {
              click: function ($event) {
                _vm.dataset.filterLine.show = !_vm.dataset.filterLine.show
              },
            },
          },
          [
            _vm._v("\n      筛选\n      "),
            _vm.dataset.filterLine.show
              ? _c("i", { staticClass: "el-icon-arrow-down" })
              : _c("i", { staticClass: "el-icon-arrow-right" }),
          ]
        ),
      ]),
      _c(
        "div",
        [
          _c("finalTable", {
            ref: "finalTableRef",
            attrs: { datas: _vm.dataset },
            on: { tableEventHandler: _vm.tableEventHandler },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.product.addDialogVisible,
            "before-close": () => {
              _vm.product.addDialogVisible = false
            },
            width: "728px",
            "destroy-on-close": true,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.product, "addDialogVisible", $event)
            },
          },
        },
        [
          _c("template", { slot: "title" }, [
            _c("span", [_vm._v(_vm._s(_vm.product.title))]),
          ]),
          _vm.product.addDialogVisible
            ? _c("edit-product", {
                attrs: { editRow: _vm.currentEditRow },
                on: { cancelProductEdit: _vm.cancelProductEdit },
              })
            : _vm._e(),
        ],
        2
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.product.copyDialogVisible,
            "before-close": _vm.cancelCopyProduct,
            width: "728",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.product, "copyDialogVisible", $event)
            },
          },
        },
        [
          _c("template", { slot: "title" }, [
            _vm._v("\n      " + _vm._s(_vm.product.copyTitle) + "\n    "),
          ]),
          _c(
            "el-form",
            {
              ref: "productForm",
              attrs: {
                "label-position": "top",
                model: _vm.product.form,
                rules: _vm.product.formRules,
              },
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 40 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("vlink.product.sourceProduct"),
                            prop: "productKey",
                          },
                        },
                        [
                          _c("el-input", {
                            staticStyle: { display: "none" },
                            attrs: { maxlength: "32" },
                            model: {
                              value: _vm.product.form.productKey,
                              callback: function ($$v) {
                                _vm.$set(_vm.product.form, "productKey", $$v)
                              },
                              expression: "product.form.productKey",
                            },
                          }),
                          _c("el-input", {
                            attrs: { maxlength: "32", readonly: "" },
                            model: {
                              value: _vm.product.copyProductName,
                              callback: function ($$v) {
                                _vm.$set(_vm.product, "copyProductName", $$v)
                              },
                              expression: "product.copyProductName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("vlink.product.newProduct"),
                            prop: "productName",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              maxlength: "32",
                              "show-word-limit": true,
                              clearable: "",
                              placeholder: _vm.$t("commons.pleaseInput"),
                            },
                            model: {
                              value: _vm.product.form.productName,
                              callback: function ($$v) {
                                _vm.$set(_vm.product.form, "productName", $$v)
                              },
                              expression: "product.form.productName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("vlink.product.label"),
                    prop: "tagList",
                  },
                },
                [
                  _c("tag-bind", {
                    attrs: {
                      tagValueList: _vm.product.form.tagList,
                      tagType: "product",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c("el-button", { on: { click: _vm.cancelCopyProduct } }, [
                _vm._v(_vm._s(_vm.$t("commons.cancel"))),
              ]),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handleCopyProduct },
                },
                [_vm._v(_vm._s(_vm.$t("commons.save")))]
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.successObj.successDialogShow,
            "before-close": () => {
              _vm.successObj.successDialogShow = false
            },
            width: "728px",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.successObj, "successDialogShow", $event)
            },
          },
        },
        [
          _c("div", { staticClass: "success-title-line" }, [
            _c("i", { staticClass: "el-icon-success" }),
            _vm._v(
              "\n      " +
                _vm._s(
                  `成功添加产品 ${_vm.successObj.productName}，下一步您可以：`
                ) +
                "\n    "
            ),
          ]),
          _c("div", { staticClass: "success-card-container" }, [
            _c(
              "div",
              {
                staticClass: "single-part",
                on: {
                  click: function ($event) {
                    return _vm.jumpHanlder("productModule")
                  },
                },
              },
              [
                _c("div", { staticClass: "title-line" }, [
                  _c("img", {
                    attrs: {
                      src: require("../../../../assets/images/success_product_icon.png"),
                      alt: "",
                    },
                  }),
                  _vm._v("\n          添加产品模块\n        "),
                ]),
                _c("div", { staticClass: "detail-line" }, [
                  _vm._v(
                    "\n          为新建的产品定义产品模型，并添加模块。产品下的设备将自动继承模型内容（属性、事件、服务）。\n        "
                  ),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "single-part",
                on: {
                  click: function ($event) {
                    return _vm.jumpHanlder("device")
                  },
                },
              },
              [
                _c("div", { staticClass: "title-line" }, [
                  _c("img", {
                    attrs: {
                      src: require("../../../../assets/images/success_device_icon.png"),
                      alt: "",
                    },
                  }),
                  _vm._v("\n          添加设备\n        "),
                ]),
                _c("div", { staticClass: "detail-line" }, [
                  _vm._v(
                    "\n          为新建的产品添加设备。填写设备名称和设备识别码，即可添加新设备，并生成设备密钥。\n        "
                  ),
                ]),
              ]
            ),
          ]),
          _c(
            "div",
            {
              staticClass: "success-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.jumpHanlder("productDetail")
                    },
                  },
                },
                [_vm._v("查看产品详情")]
              ),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.jumpHanlder("productNew")
                    },
                  },
                },
                [_vm._v("继续添加产品")]
              ),
            ],
            1
          ),
        ]
      ),
      _c("RuleDrawerComponent", {
        attrs: { show: _vm.ruleDrawer.show, datas: _vm.ruleDrawer.datas },
        on: {
          close: function ($event) {
            _vm.ruleDrawer.show = false
            _vm.ruleDrawer.datas = {}
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }