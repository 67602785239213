<template>
  <div>
    <el-form
      label-position="top"
      ref="productForm"
      :rules="productFormRules"
      :model="productForm"
      label-width="100px"
    >
      <el-row :gutter="40">
        <el-col :span="12">
          <el-form-item label="产品模板" prop="">
            <el-select
              style="width: 100%"
              @change="productTemplateChange"
              v-model="productTemplate"
              filterable
              remote
              reserve-keyword
              placeholder="请输入产品名称"
              :remote-method="queryProductTemplate"
              :loading="productTemplateLoading"
            >
              <el-option
                v-for="item in productTemplateOptions"
                :key="`${item.productKey}&${item.productName}`"
                :label="item.productName"
                :value="`${item.productKey}&${item.productName}`"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            :label="$t('vlink.product.productName')"
            prop="productName"
          >
            <el-input
              v-model="productForm.productName"
              maxlength="25"
              :show-word-limit="true"
              :placeholder="$t('commons.pleaseInput')"
              clearable
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item :label="$t('vlink.product.nodeType')" prop="nodeType">
            <el-select
              v-model="productForm.nodeType"
              :style="{ width: '100%' }"
            >
              <el-option
                value="DIRECTDEVICE"
                :label="$t('vlink.commons.directDevice')"
              ></el-option>
              <el-option
                value="GATEWAY"
                :label="$t('vlink.commons.gateway')"
              ></el-option>
              <el-option
                value="SUBDEVICE"
                :label="$t('vlink.commons.subDevice')"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item :label="$t('vlink.product.dataType')" prop="dataType">
            <el-select
              v-model="productForm.dataType"
              :style="{ width: '100%' }"
            >
              <el-option
                value="JSON"
                :label="$t('vlink.commons.json')"
              ></el-option>
              <el-option
                value="BINARY"
                :label="$t('vlink.commons.binary')"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item :label="$t('vlink.product.authType')" prop="authType">
            <el-select
              v-model="productForm.authType"
              :style="{ width: '100%' }"
            >
              <el-option
                value="SECRETKEY"
                :label="$t('vlink.commons.secretkey')"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item :label="$t('vlink.product.protocol')" prop="protocol">
            <el-select
              v-model="productForm.protocol"
              :style="{ width: '100%' }"
            >
              <el-option
                value="custom"
                :label="$t('vlink.commons.custom')"
              ></el-option>
              <el-option
                value="modbus"
                :label="$t('vlink.commons.modbus')"
              ></el-option>
              <el-option
                value="bacnet"
                :label="$t('vlink.commons.bacnet')"
              ></el-option>
              <el-option
                value="opcua"
                :label="$t('vlink.commons.opcua')"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            :label="$t('vlink.product.description')"
            prop="description"
          >
            <el-input
              v-model="productForm.description"
              :placeholder="$t('commons.pleaseInput')"
              clearable
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-form-item :label="$t('vlink.product.label')" prop="tagList">
        <tag-bind :tagValueList="productForm.tagList" tagType="product" />
      </el-form-item>

      <el-row style="margin-bottom: 10px">
        <el-col :span="24" align="right">
          <el-button @click="cancelProductEdit">{{
            $t("commons.cancel")
          }}</el-button>
          <el-button
            type="primary"
            @click="handleProductSave"
            v-loading="createLoading"
            >{{ $t("commons.add") }}
          </el-button>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import {
  createProduct,
  updateProduct,
  getSharedProductList,
} from "@/api/ruge/vlink/product/product";
import TagBind from "@/components/TagBind";

export default {
  components: {
    TagBind,
  },
  name: "ProductManager",
  props: {
    editRow: {
      required: false,
    },
  },
  watch: {
    editRow: {
      handler(row) {
        if (row) {
          this.productForm = row;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      productTemplate: null,
      productTemplateLoading: false,
      productTemplateOptions: [],
      createLoading: false,
      productForm: {
        productId: null,
        productName: null,
        nodeType: "DIRECTDEVICE",
        dataType: "JSON",
        authType: "SECRETKEY",
        protocol: "custom",
        description: "",
        tagList: [],
        tslJson: null,
      },
      productFormRules: {
        productName: [
          {
            required: true,
            trigger: ["blur", "change"],
            message: this.$t("validate.required"),
          },
          {
            min: 2,
            message: this.$t("vlink.product.productNameRule1"),
            trigger: ["blur"],
          },
          {
            pattern: /^[A-Za-z0-9-_@#()\u4e00-\u9fa5]+$/,
            message: this.$t("vlink.product.productNameRule2"),
            trigger: ["blur", "change"],
          },
        ],
        nodeType: [
          {
            required: true,
            trigger: ["blur", "change"],
            message: this.$t("validate.required"),
          },
        ],
        dataType: [
          {
            required: true,
            trigger: ["blur", "change"],
            message: this.$t("validate.required"),
          },
        ],
        authType: [
          {
            required: true,
            trigger: ["blur", "change"],
            message: this.$t("validate.required"),
          },
        ],
        protocol: [
          {
            required: true,
            trigger: ["blur", "change"],
            message: this.$t("validate.required"),
          },
        ],
      },
    };
  },
  created() {
    this.getSharedProducts();
  },
  methods: {
    productTemplateChange(product) {
      const [productKey, productName] = product.split("&") || [];
      const current = this.productTemplateOptions.filter(
        (item) =>
          item.productKey === productKey && item.productName === productName
      )[0];
      const { nodeType, dataType, authType, protocol, tslJson } = current || {};
      this.productForm.productName = current.productName;
      this.productForm.nodeType = nodeType;
      this.productForm.dataType = dataType;
      this.productForm.authType = authType;
      this.productForm.protocol = protocol;
      this.productForm.tslJson = tslJson;
    },
    queryProductTemplate(productName) {
      this.getSharedProducts(productName);
    },
    getSharedProducts(productName) {
      getSharedProductList({
        productName,
      }).then((res) => {
        this.productTemplateOptions = res;
        // .map((item) => {
        //   return {
        //     value: item.productKey,
        //     label: item.productName,
        //   };
        // });
      });
    },
    cancelProductEdit() {
      this.$emit("cancelProductEdit", {
        reload: false,
      });
    },
    handleProductSave() {
      this.$refs.productForm.validate((v) => {
        if (v) {
          // tagList去重复标签
          this.productForm.tagList = Array.from(
            new Set(this.productForm.tagList)
          );
          this.createLoading = true;
          if (this.productForm.productId == null) {
            console.log("this.productForm", this.productForm);
            createProduct(this.productForm)
              .then((res) => {
                // 成功提示
                this.$message({
                  message: this.$t("message.saveSuccess"),
                  type: "success",
                });
                this.$emit("cancelProductEdit", {
                  reload: true,
                  productName: this.productForm.productName,
                  productKey: res.productKey,
                  productId: res.productId,
                });
              })
              .catch((error) => {
                console.log(`保存失败，原因 => ${error}`);
              })
              .finally(() => {
                this.createLoading = false;
              });
          } else {
            updateProduct(this.productForm)
              .then((res) => {
                // 成功提示
                this.$message({
                  message: this.$t("message.saveSuccess"),
                  type: "success",
                });
                this.$emit("cancelProductEdit", {
                  reload: true,
                  productName: this.productForm.productName,
                  productKey: res.productKey,
                  productId: res.productId,
                });
              })
              .catch((error) => {
                console.log(`保存失败，原因 => ${error}`);
              })
              .finally(() => {
                this.createLoading = false;
              });
          }
        }
      });
    },
  },
};
</script>