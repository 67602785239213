var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "productForm",
          attrs: {
            "label-position": "top",
            rules: _vm.productFormRules,
            model: _vm.productForm,
            "label-width": "100px",
          },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 40 } },
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "产品模板", prop: "" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            filterable: "",
                            remote: "",
                            "reserve-keyword": "",
                            placeholder: "请输入产品名称",
                            "remote-method": _vm.queryProductTemplate,
                            loading: _vm.productTemplateLoading,
                          },
                          on: { change: _vm.productTemplateChange },
                          model: {
                            value: _vm.productTemplate,
                            callback: function ($$v) {
                              _vm.productTemplate = $$v
                            },
                            expression: "productTemplate",
                          },
                        },
                        _vm._l(_vm.productTemplateOptions, function (item) {
                          return _c("el-option", {
                            key: `${item.productKey}&${item.productName}`,
                            attrs: {
                              label: item.productName,
                              value: `${item.productKey}&${item.productName}`,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("vlink.product.productName"),
                        prop: "productName",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          maxlength: "25",
                          "show-word-limit": true,
                          placeholder: _vm.$t("commons.pleaseInput"),
                          clearable: "",
                        },
                        model: {
                          value: _vm.productForm.productName,
                          callback: function ($$v) {
                            _vm.$set(_vm.productForm, "productName", $$v)
                          },
                          expression: "productForm.productName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("vlink.product.nodeType"),
                        prop: "nodeType",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          style: { width: "100%" },
                          model: {
                            value: _vm.productForm.nodeType,
                            callback: function ($$v) {
                              _vm.$set(_vm.productForm, "nodeType", $$v)
                            },
                            expression: "productForm.nodeType",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: {
                              value: "DIRECTDEVICE",
                              label: _vm.$t("vlink.commons.directDevice"),
                            },
                          }),
                          _c("el-option", {
                            attrs: {
                              value: "GATEWAY",
                              label: _vm.$t("vlink.commons.gateway"),
                            },
                          }),
                          _c("el-option", {
                            attrs: {
                              value: "SUBDEVICE",
                              label: _vm.$t("vlink.commons.subDevice"),
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("vlink.product.dataType"),
                        prop: "dataType",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          style: { width: "100%" },
                          model: {
                            value: _vm.productForm.dataType,
                            callback: function ($$v) {
                              _vm.$set(_vm.productForm, "dataType", $$v)
                            },
                            expression: "productForm.dataType",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: {
                              value: "JSON",
                              label: _vm.$t("vlink.commons.json"),
                            },
                          }),
                          _c("el-option", {
                            attrs: {
                              value: "BINARY",
                              label: _vm.$t("vlink.commons.binary"),
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("vlink.product.authType"),
                        prop: "authType",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          style: { width: "100%" },
                          model: {
                            value: _vm.productForm.authType,
                            callback: function ($$v) {
                              _vm.$set(_vm.productForm, "authType", $$v)
                            },
                            expression: "productForm.authType",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: {
                              value: "SECRETKEY",
                              label: _vm.$t("vlink.commons.secretkey"),
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("vlink.product.protocol"),
                        prop: "protocol",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          style: { width: "100%" },
                          model: {
                            value: _vm.productForm.protocol,
                            callback: function ($$v) {
                              _vm.$set(_vm.productForm, "protocol", $$v)
                            },
                            expression: "productForm.protocol",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: {
                              value: "custom",
                              label: _vm.$t("vlink.commons.custom"),
                            },
                          }),
                          _c("el-option", {
                            attrs: {
                              value: "modbus",
                              label: _vm.$t("vlink.commons.modbus"),
                            },
                          }),
                          _c("el-option", {
                            attrs: {
                              value: "bacnet",
                              label: _vm.$t("vlink.commons.bacnet"),
                            },
                          }),
                          _c("el-option", {
                            attrs: {
                              value: "opcua",
                              label: _vm.$t("vlink.commons.opcua"),
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("vlink.product.description"),
                        prop: "description",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: _vm.$t("commons.pleaseInput"),
                          clearable: "",
                        },
                        model: {
                          value: _vm.productForm.description,
                          callback: function ($$v) {
                            _vm.$set(_vm.productForm, "description", $$v)
                          },
                          expression: "productForm.description",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: { label: _vm.$t("vlink.product.label"), prop: "tagList" },
            },
            [
              _c("tag-bind", {
                attrs: {
                  tagValueList: _vm.productForm.tagList,
                  tagType: "product",
                },
              }),
            ],
            1
          ),
          _c(
            "el-row",
            { staticStyle: { "margin-bottom": "10px" } },
            [
              _c(
                "el-col",
                { attrs: { span: 24, align: "right" } },
                [
                  _c("el-button", { on: { click: _vm.cancelProductEdit } }, [
                    _vm._v(_vm._s(_vm.$t("commons.cancel"))),
                  ]),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.createLoading,
                          expression: "createLoading",
                        },
                      ],
                      attrs: { type: "primary" },
                      on: { click: _vm.handleProductSave },
                    },
                    [_vm._v(_vm._s(_vm.$t("commons.add")) + "\n        ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }