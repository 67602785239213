<template>
  <div class="app-container product-list-comp">
    <div class="filter-line">
      <span>
        <el-button type="primary" @click="createProduct">添加产品</el-button>
        <el-tooltip
          class="item"
          effect="dark"
          :disabled="selectionList.length > 0"
          :content="$t('commons.multipleSelectionOne')"
          placement="right"
        >
          <el-button
            type="danger"
            :class="selectionList.length === 0 && 'not-allowed-button'"
            icon="el-icon-delete"
            @click="batchDelete"
            plain
            >{{ $t("commons.delete") }}
          </el-button>
        </el-tooltip>
        <el-button plain icon="el-icon-refresh-right" @click="freshHandler"
          >刷新
        </el-button>
      </span>
      <div
        class="header-filter-button"
        @click="dataset.filterLine.show = !dataset.filterLine.show"
        :class="
          dataset.filterLine.show ? 'filter-button-open' : 'filter-button-close'
        "
      >
        筛选
        <i class="el-icon-arrow-down" v-if="dataset.filterLine.show"></i>
        <i class="el-icon-arrow-right" v-else></i>
      </div>
    </div>

    <div>
      <finalTable
        ref="finalTableRef"
        :datas="dataset"
        @tableEventHandler="tableEventHandler"
      />
    </div>

    <!--编辑产品弹框-->
    <el-dialog
      :visible.sync="product.addDialogVisible"
      :before-close="
        () => {
          product.addDialogVisible = false;
        }
      "
      width="728px"
      :destroy-on-close="true"
      :close-on-click-modal="false"
    >
      <template slot="title">
        <span>{{ product.title }}</span>
      </template>
      <edit-product
        v-if="product.addDialogVisible"
        @cancelProductEdit="cancelProductEdit"
        :editRow="currentEditRow"
      ></edit-product>
    </el-dialog>
    <!-- 复制产品对话框 -->
    <el-dialog
      :visible.sync="product.copyDialogVisible"
      :before-close="cancelCopyProduct"
      width="728"
      :close-on-click-modal="false"
    >
      <template slot="title">
        {{ product.copyTitle }}
      </template>
      <el-form
        label-position="top"
        :model="product.form"
        ref="productForm"
        :rules="product.formRules"
      >
        <el-row :gutter="40">
          <el-col :span="12">
            <el-form-item
              :label="$t('vlink.product.sourceProduct')"
              prop="productKey"
            >
              <el-input
                v-model="product.form.productKey"
                maxlength="32"
                style="display: none"
              ></el-input>
              <el-input
                v-model="product.copyProductName"
                maxlength="32"
                readonly
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              :label="$t('vlink.product.newProduct')"
              prop="productName"
            >
              <el-input
                v-model="product.form.productName"
                maxlength="32"
                :show-word-limit="true"
                clearable
                :placeholder="$t('commons.pleaseInput')"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item :label="$t('vlink.product.label')" prop="tagList">
          <tag-bind :tagValueList="product.form.tagList" tagType="product" />
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="cancelCopyProduct">{{
          $t("commons.cancel")
        }}</el-button>
        <el-button type="primary" @click="handleCopyProduct">{{
          $t("commons.save")
        }}</el-button>
      </div>
    </el-dialog>
    <el-dialog
      :visible.sync="successObj.successDialogShow"
      :before-close="
        () => {
          successObj.successDialogShow = false;
        }
      "
      width="728px"
      :close-on-click-modal="false"
    >
      <div class="success-title-line">
        <i class="el-icon-success"></i>
        {{ `成功添加产品 ${successObj.productName}，下一步您可以：` }}
      </div>
      <div class="success-card-container">
        <div class="single-part" @click="jumpHanlder('productModule')">
          <div class="title-line">
            <img
              src="../../../../assets/images/success_product_icon.png"
              alt=""
            />
            添加产品模块
          </div>
          <div class="detail-line">
            为新建的产品定义产品模型，并添加模块。产品下的设备将自动继承模型内容（属性、事件、服务）。
          </div>
        </div>
        <div class="single-part" @click="jumpHanlder('device')">
          <div class="title-line">
            <img
              src="../../../../assets/images/success_device_icon.png"
              alt=""
            />
            添加设备
          </div>
          <div class="detail-line">
            为新建的产品添加设备。填写设备名称和设备识别码，即可添加新设备，并生成设备密钥。
          </div>
        </div>
      </div>
      <div slot="footer" class="success-footer">
        <el-button type="primary" @click="jumpHanlder('productDetail')"
          >查看产品详情</el-button
        >
        <el-button @click="jumpHanlder('productNew')">继续添加产品</el-button>
      </div>
    </el-dialog>
    <!-- 关联规则抽屉 -->
    <RuleDrawerComponent
      :show="ruleDrawer.show"
      :datas="ruleDrawer.datas"
      @close="
        ruleDrawer.show = false;
        ruleDrawer.datas = {};
      "
    />
  </div>
</template>

<script>
import {
  findProductPage,
  deleteProductById,
  batchDeleteProduct,
  updateDynamicRegistration,
  copyProduct,
  downProductTemplate,
  updateproductShareState,
} from "@/api/ruge/vlink/product/product";
import EditProduct from "@/views/ruge/vlink/product/editProduct";
// import Pagination from "@/components/Pagination";
// import Detail from "@/views/ruge/vlink/product/detail";
// import Upload from "@/components/Upload";
import TagBind from "@/components/TagBind";
import finalTable from "@/components/FinalTable";
import RuleDrawerComponent from "@/components/RuleDrawerComponent";
export default {
  name: "ProductManager",
  components: {
    // Pagination,
    EditProduct,
    // Detail,
    // Upload,
    TagBind,
    finalTable,
    RuleDrawerComponent,
  },
  data() {
    return {
      ruleDrawer: {
        show: false,
        datas: {},
      },
      successObj: {
        productName: "",
        productKey: "",
        productId: "",
        successDialogShow: false,
      },
      loadingFlag: false,
      dataset: {
        paginationConfig: {
          need: true,
        },
        filterLine: {
          show: true,
        },
        selection: {
          need: true,
          prop: "productId",
          width: "18",
        },
        header: [
          { prop: "productName", label: "产品名称", width: "" },
          { prop: "productKey", label: "产品标识码", width: "" },
          { prop: "nodeType", label: "节点类型", width: "" },
          { prop: "protocol", label: "协议类型", width: "" },
          { prop: "dynamicRegistration", label: "设备动态注册", width: "" },
          { prop: "isShared", label: "是否共享", width: "" },
          { prop: "creationDate", label: "创建时间", width: "" },
          { prop: "operation", label: "操作", width: "225" },
        ],
        tableData: [],
        // 搜索行配置
        searchLineConfig: {
          productName: {
            type: "input",
            label: "产品名称",
            value: "",
            actionType: "goSearch",
            placeholder: "请输入产品名称",
            prefixIcon: "el-icon-search",
          },
          productKey: {
            type: "input",
            label: "产品标识码",
            value: "",
            actionType: "goSearch",
            placeholder: "请输入产品标识码",
            prefixIcon: "el-icon-search",
          },
          nodeType: {
            type: "selector",
            label: "节点类型",
            value: "",
            placeholder: "请选择节点类型",
            actionType: "goSearch",
            optionList: [
              {
                label: "直连设备",
                value: "DIRECTDEVICE",
              },
              {
                label: "网关设备",
                value: "GATEWAY",
              },
              {
                label: "网关子设备",
                value: "SUBDEVICE",
              },
            ],
          },
          protocol: {
            type: "selector",
            label: "协议类型",
            value: "",
            placeholder: "请选择协议类型",
            actionType: "goSearch",
            optionList: [
              {
                label: this.$t("vlink.commons.custom"),
                value: "custom",
              },
              {
                label: this.$t("vlink.commons.modbus"),
                value: "modbus",
              },
              {
                label: this.$t("vlink.commons.bacnet"),
                value: "bacnet",
              },
              {
                label: this.$t("vlink.commons.opcua"),
                value: "opcua",
              },
            ],
          },
          dynamicRegistration: {
            type: "selector",
            label: "设备动态注册",
            actionType: "goSearch",
            value: "",
            placeholder: "请选择设备动态注册",
            optionList: [
              {
                label: "启用",
                value: "ENABLE",
              },
              {
                label: "禁用",
                value: "DISABLE",
              },
            ],
          },
          isShared: {
            type: "selector",
            label: "是否共享",
            actionType: "goSearch",
            value: "",
            placeholder: "请选择是否共享",
            optionList: [
              {
                label: "是",
                value: "Y",
              },
              {
                label: "否",
                value: "N",
              },
            ],
          },
          operation: {
            type: "button",
            filterCount: "",
            actionType: "clickEvent",
            eventName: "showMoreFilters",
            label: "更多筛选",
          },
        },
        // 表格内容配置
        detailConfig: {
          productName: {
            type: "aLink",
            actionType: "aLinkClick",
            targetType: "product",
          },
          nodeType: {
            type: "enumerationColumn",
            emuList: {
              DIRECTDEVICE: "直连设备",
              GATEWAY: "网关设备",
              SUBDEVICE: "网关子设备",
            },
          },
          protocol: {
            type: "enumerationColumn",
            emuList: {
              custom: this.$t("vlink.commons.custom"),
              modbus: this.$t("vlink.commons.modbus"),
              bacnet: this.$t("vlink.commons.bacnet"),
              opcua: this.$t("vlink.commons.opcua"),
            },
          },
          dynamicRegistration: {
            type: "switch",
            actionType: "switchEvent",
            eventName: "dynamicRegistrationChange",
          },
          isShared: {
            // type: "enumerationColumn",
            // emuList: {
            //   Y: "是",
            //   N: "否",
            // },
            type: "switch",
            actionType: "switchEvent",
            eventName: "isSharedChange",
          },
          creationDate: {
            type: "dateFormat",
          },
          operation: {
            type: "icon",
            iconList: [
              {
                actionType: "iconClick",
                eventName: "copy",
                fontSize: "14px",
                color: "#1A4CEC",
                cursorPointer: "pointer",
                iconName: "el-icon-document-copy",
                tooltips: "复制",
              },
              {
                actionType: "iconClick",
                eventName: "view",
                fontSize: "14px",
                color: "#1A4CEC",
                cursorPointer: "pointer",
                iconName: "el-icon-view",
                tooltips: "查看详情",
              },
              {
                actionType: "iconClick",
                eventName: "rule",
                fontSize: "14px",
                color: "#1A4CEC",
                cursorPointer: "pointer",
                iconName: "el-icon-document-copy",
                tooltips: "关联规则",
              },
              {
                actionType: "iconClick",
                eventName: "delete",
                fontSize: "14px",
                color: "#E47470",
                cursorPointer: "pointer",
                iconName: "el-icon-delete",
                tooltips: "删除",
              },
            ],
          },
        },
        // 高级搜索配置
        advanceFilterConfig: {
          productName: {
            inline: true,
            value: "",
          },
          productKey: {
            inline: true,
            value: "",
          },
          nodeType: {
            inline: true,
            value: "",
          },
          dynamicRegistration: {
            inline: true,
            value: "",
          },
          tagList: {
            type: "tagList",
            label: "标签",
            tagType: "product",
            value: [],
          },
          creationDate: {
            type: "dateRange",
            label: "创建时间",
            value: [],
          },
        },
        pageVO: {
          current: 1,
          rowCount: 10,
          total: 0,
        },
      },
      selectionList: [],
      filterObj: {
        current: 1,
        total: 0,
        rowCount: 10,
        productName: "",
        productKey: "",
        nodeType: "",
        dynamicRegistration: "",
        creationStartTime: null,
        creationEndTime: null,
      },
      // Tooltip显示延迟时间(毫秒)
      openDelay: 200,
      detailTab: null,
      currentRow: {
        productId: null,
        productKey: "",
        nodeType: "",
        dataType: "",
      },
      currentEditRow: null,
      product: {
        form: {
          productKey: null,
          productName: null,
          tagList: [],
        },
        copyProductName: null,
        copyDialogVisible: false,
        copyTitle: this.$t("vlink.product.copy"),
        title: null,
        total: 0,
        list: null,
        listLoading: false,
        listQuery: {
          current: 1,
          rowCount: 10,
          productName: null,
          nodeType: null,
          productKey: null,
        },
        addDialogVisible: false,
        createLoading: false,
        formRules: {
          productName: [
            {
              required: true,
              trigger: ["blur", "change"],
              message: this.$t("validate.required"),
            },
            {
              min: 2,
              message: this.$t("vlink.product.productNameRule1"),
              trigger: ["blur"],
            },
            {
              pattern: /^[A-Za-z0-9-_@#()\u4e00-\u9fa5]+$/,
              message: this.$t("vlink.product.productNameRule2"),
              trigger: ["blur", "change"],
            },
          ],
        },
      },
    };
  },
  created() {
    // 如果从产品返回，则需要回显上次的查询条件
    if (this.$route.query.goBackFlag) {
      const curSearch = sessionStorage.getItem("product_list_query_params");
      if (curSearch) {
        const tempObj = JSON.parse(curSearch);
        this.filterObj = { ...this.filterObj, ...tempObj };
        this.dataset.pageVO.current = this.filterObj.current;
        this.dataset.pageVO.rowCount = this.filterObj.rowCount;
        Object.keys(this.filterObj).forEach((item) => {
          if (this.dataset.searchLineConfig[item]) {
            this.dataset.searchLineConfig[item].value = this.filterObj[item];
          }
        });
        // 处理高级查询中的taglist和创建时间
        let dataTemp = [
          tempObj.creationStartTime || null,
          tempObj.creationEndTime || null,
        ];
        this.dataset.advanceFilterConfig.creationDate.value = dataTemp;
        if (tempObj.tagList && tempObj.tagList.length > 0) {
          this.dataset.advanceFilterConfig.tagList.value =
            tempObj.tagList.split(",");
        }
      }
    }
    this.getProductPage();
  },
  mounted() {
    // 从总览跳转过来
    const { type } = this.$route.params;
    if (type === "product") {
      this.product.title = this.$t("vlink.product.add");
      this.product.addDialogVisible = true;
    }
  },
  watch: {
    loadingFlag: {
      handler(val) {
        this.$refs.finalTableRef.loadingToggle(val);
      },
    },
  },
  methods: {
    jumpHanlder(type) {
      const params = { type };
      switch (type) {
        case "device":
          this.$router.push({
            name: "VlinkDeviceList",
            query: {
              type,
              defaultProduct: this.successObj.productId,
            },
          });
          break;
        case "productModule":
          this.$router.push({
            path: "/iot/product/detail",
            query: {
              productKey: this.successObj.productKey,
              activeTabs: "tsl",
            },
          });
          break;
        case "productDetail":
          this.$router.push({
            path: "/iot/product/detail",
            query: {
              productKey: this.successObj.productKey,
            },
          });
          break;
        case "productNew":
          this.successObj.successDialogShow = false;
          this.product.addDialogVisible = true;
          break;
      }
    },
    freshHandler(evt) {
      let target = evt.target;
      if (target.nodeName == "SPAN") {
        target = evt.target.parentNode;
      }
      target.blur();
      this.getProductPage();
    },
    updateShareState(datas) {
      this.loadingFlag = true;
      updateproductShareState({
        productId: datas.productId,
        isShared: datas.isShared === "ENABLE" ? "Y" : "N",
      })
        .then((res) => {
          // 成功提示
          this.$message({
            message: this.$t("message.saveSuccess"),
            type: "success",
          });
          this.findProductPage();
        })
        .catch((error) => {
          console.log(`保存失败，原因 => ${error}`);
        })
        .finally(() => {
          this.loadingFlag = false;
        });
    },
    tableEventHandler(datas) {
      if (datas.type === "goSearch") {
        if (datas.params.tagList && datas.params.tagList.length > 0)
          datas.params.tagList = datas.params.tagList.join(",");
        this.dealTime(datas);
        this.filterObj = { ...this.filterObj, ...datas.params };
        this.findProductPage();
      } else if (datas.type === "paginationChange") {
        this.filterObj.current = datas.params.current.page;
        this.filterObj.rowCount = datas.params.current.limit;
        this.getProductPage();
      } else if (datas.type === "updateSelectionList") {
        this.selectionList = datas.list;
      } else if (datas.type === "switchEvent") {
        console.log("datas.eventName", datas);
        switch (datas.eventName) {
          case "dynamicRegistrationChange":
            this.updateDynamicRegistration(datas.row);
            break;
          case "isSharedChange":
            this.updateShareState(datas.row);
            break;
        }
      } else if (datas.type === "iconClick") {
        switch (datas.eventName) {
          case "copy":
            this.copyProductView(datas.row);
            break;
          case "view":
            this.viewProductDetail(datas.row);
            break;
          case "delete":
            this.deleteProduct(datas.row.productId);
            break;
          case "rule":
            this.ruleDrawerHandler(datas.row);
            break;
        }
      }
    },
    ruleDrawerHandler(datas) {
      this.ruleDrawer.show = true;
      this.ruleDrawer.datas = datas;
    },
    dealTime(datas) {
      if (datas.params.creationDate && datas.params.creationDate.length > 0) {
        this.filterObj.creationStartTime = datas.params.creationDate[0]
          ? new Date(datas.params.creationDate[0]).getTime()
          : null;
        this.filterObj.creationEndTime = datas.params.creationDate[1]
          ? new Date(datas.params.creationDate[1]).getTime()
          : null;
        delete datas.params.creationDate;
      } else {
        this.filterObj.creationStartTime = null;
        this.filterObj.creationEndTime = null;
      }
      return datas;
    },
    findProductPage() {
      this.filterObj.current = 1;
      this.dataset.pageVO.current = 1;
      this.getProductPage();
    },
    getProductPage() {
      // let param = this.product.listQuery;
      const param = this.filterObj;
      sessionStorage.setItem(
        "product_list_query_params",
        JSON.stringify(param)
      );
      for (let pn in param) {
        !param[pn] && param[pn] !== 0 && (param[pn] = null);
      }
      delete param.total;
      this.loadingFlag = true;
      findProductPage(param)
        .then((response) => {
          this.dataset.pageVO.total = response.total;
          this.dataset.tableData = response.rows.map((item) => {
            item.isShared = item.isShared === "Y" ? "ENABLE" : "DISABLED";
            return item;
          });
          this.loadingFlag = false;
        })
        .catch((err) => {
          this.loadingFlag = false;
          this.$message.warning("查询失败！");
        });
    },
    cancelProductEdit({ reload, productName, productKey, productId }) {
      this.product.addDialogVisible = false;
      if (reload == true) {
        this.findProductPage();
        this.successObj.successDialogShow = true;
        this.successObj.productName = productName;
        this.successObj.productKey = productKey;
        this.successObj.productId = productId;
      }
    },
    createProduct() {
      this.product.title = this.$t("vlink.product.add");
      this.currentEditRow = null;
      this.product.addDialogVisible = true;
    },
    deleteProduct(productId) {
      this.$confirm(
        this.$t("message.deleteConfirm"),
        this.$t("commons.warning"),
        {
          confirmButtonText: this.$t("commons.confirm"),
          cancelButtonText: this.$t("commons.cancel"),
          type: "warning",
        }
      )
        .then(() => {
          deleteProductById({ productId: productId }).then(() => {
            // 成功提示
            this.$message({
              message: this.$t("message.deleteSuccess"),
              type: "success",
            });
            this.findProductPage();
          });
        })
        .catch((error) => {
          console.log(`未删除，原因 => ${error}`);
        });
    },
    batchDelete() {
      if (this.selectionList.length < 1) {
        return;
      }
      const productIdList = this.selectionList.map((item) => item.productId);
      this.$confirm(
        this.$t("message.deleteConfirm"),
        this.$t("commons.warning"),
        {
          confirmButtonText: this.$t("commons.confirm"),
          cancelButtonText: this.$t("commons.cancel"),
          type: "warning",
        }
      )
        .then(() => {
          batchDeleteProduct({
            productIdList,
          }).then(() => {
            // 成功提示
            this.$message({
              message: this.$t("message.deleteSuccess"),
              type: "success",
            });
            this.findProductPage();
          });
        })
        .catch((error) => {
          console.log(`未删除，原因 => ${error}`);
        });
    },
    updateDynamicRegistration(row) {
      this.loadingFlag = true;
      updateDynamicRegistration(row)
        .then(() => {
          // 成功提示
          this.$message({
            message: this.$t("message.saveSuccess"),
            type: "success",
          });
          this.findProductPage();
        })
        .catch((error) => {
          this.findProductPage();
          console.log(`保存失败，原因 => ${error}`);
        })
        .finally(() => {
          this.loadingFlag = false;
        });
    },
    viewProductDetail(row) {
      this.$router.push({
        path: "/iot/product/detail",
        query: {
          productKey: row.productKey,
          // productId: row.productId,
          // nodeType: row.nodeType,
          // detailAlias: row.productName,
        },
      });
    },
    copyProductView(row) {
      this.product.form.productKey = row.productKey;
      this.product.copyProductName = row.productName;
      this.product.form.productName = row.productName;
      this.product.form.tagList = row.tagList;
      this.product.copyDialogVisible = true;
    },
    // 取消复制产品
    cancelCopyProduct() {
      this.product.copyDialogVisible = false;
    },
    // 处理产品复制
    handleCopyProduct() {
      this.$refs.productForm.validate((v) => {
        if (v) {
          // tagList去重复标签
          this.product.form.tagList = Array.from(
            new Set(this.product.form.tagList)
          );
          copyProduct(this.product.form)
            .then(() => {
              // 成功提示
              this.$message({
                message: this.$t("message.operationSuccess"),
                type: "success",
              });
              this.cancelCopyProduct();
              this.findProductPage();
            })
            .catch((error) => {
              console.log(`保存失败，原因 => ${error}`);
            });
        }
      });
    },
  },
};
</script>
<style scoped lang="less">
.product-list-comp {
  padding: 32px 24px;
  .filter-line {
    margin-bottom: 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    button {
      height: 36px;
    }
    .icon-loudoutu {
      font-size: 12px;
    }
    .header-filter-button {
      cursor: pointer;
      width: 84px;
      height: 36px;
      line-height: 36px;
      text-align: center;
      border-radius: 3px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      border: 1px solid #e7e8eb;
      font-weight: 400;
    }
    .not-allowed-button {
      cursor: not-allowed;
      background-color: #fef0f0;
      border-color: #fde2e2;
      color: #f9a7a7;
    }
  }
  .row-a-link {
    color: #409eff;
  }
  .advance-filter-container {
    height: 100%;
    width: 100%;
    padding: 32px 32px 96px 32px;
    position: relative;
    .button-container {
      width: 100%;
      height: 64px;
      line-height: 64px;
      position: absolute;
      bottom: 0;
      left: 0;
      display: flex;
      .filter-button {
        text-align: center;
        cursor: pointer;
        background-color: #0486fe;
        color: #fff;
        font-size: 18px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        flex: 2;
      }
      .clear-button {
        border: 1px solid #c0c4cc;
        text-align: center;
        cursor: pointer;
        background-color: #fff;
        color: #606266;
        font-size: 18px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        flex: 1;
      }
    }
  }
  .success-title-line {
    font-size: 16px;
    font-family: PingFang-SC-Bold, PingFang-SC;
    font-weight: bold;
    color: #303133;
    display: flex;
    align-items: center;
    justify-content: center;
    .el-icon-success {
      color: #67c23a;
      font-size: 24px;
      margin-right: 15px;
    }
  }
  .success-card-container {
    display: flex;
    justify-content: space-between;
    margin: 65px 0 50px;
    .single-part {
      width: 325px;
      height: 160px;
      border-radius: 6px;
      border: 1px solid #dcdfe6;
      padding: 25px;
      cursor: pointer;
      .title-line {
        color: #1a4cec;
        display: flex;
        align-items: center;
        font-weight: 500;
        font-size: 14px;
        img {
          margin-right: 12px;
        }
      }
      .detail-line {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #606266;
        line-height: 24px;
        padding-left: 44px;
      }
    }
  }
  .success-footer {
    text-align: center;
  }
  /deep/ .el-drawer__header {
    padding-left: 32px;
  }
  /deep/ .el-drawer__header > :first-child {
    outline: 0;
    font-size: 18px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #303133;
  }
  /deep/ .display-none-row {
    display: none;
  }
  // 设置form的label的样式
  /deep/ .el-form-item__label {
    font-size: 14px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #606266;
  }

  /deep/ .el-range-separator {
    min-width: 30px;
  }
  /deep/ .el-table .cell {
    padding-right: 0 !important;
  }
  /deep/ .el-pagination .el-select .el-input {
    margin-right: 0;
  }
  /deep/ .el-pagination__sizes {
    float: right;
    margin-right: 0;
  }
  /deep/ .el-pagination {
    padding-right: 0;
  }
  /deep/ .el-pagination__total {
    float: right;
  }
  /deep/ .el-dialog__header {
    font-size: 18px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #303133;
    padding: 20px 20px 10px 32px;
  }
}
</style>